<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t('Add Absent') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ $t('Add Absent') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-autocomplete :dark="$store.state.isDarkMode" dense outlined hide-details v-model="newAbsent.type" :items="absentsTypes"
                :label="$t('Absent Type')" item-value="value" item-text="name"></v-autocomplete>
            </v-col>
            <v-col md="12">
              <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.vacation.note')" v-model="newAbsent.note" dense outlined hide-details></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
              v-model="newAbsent.date"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="white--text" color="green" depressed @click="submit" :loading="submitLoading">{{ $t('add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      absentsTypes: [
      {
          name: "justified",
          value: "justified",
        },
        {
          name: "unjustified",
          value: "unjustified",
        },
      ],
      newAbsent: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        note: "",
        registration_record_id: null,
        type: "justified",
      },
      dialog: false,
      dateDialog: false,
      submitLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },
  methods: {
    async submit() {
      try {
        this.newAbsent.registration_record_id = this.getStudentRegId;
        this.submitLoading = true;
        await axios.post("/absent/registration-record", this.newAbsent);
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.dialog = false;
        this.$emit("absentSubmitted");
      } catch (err) {
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style></style>