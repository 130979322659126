<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <template v-slot:activator="{ on, attrs }">
        <v-btn rounded v-on="on" v-bind="attrs" color="green" depressed class="white--text">
          <v-icon>mdi-plus</v-icon>
          {{ $t('add note') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1> {{ $t('add note') }} </h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-autocomplete :dark="$store.state.isDarkMode"  outlined dense :label="$t('note type')" :items="typeItems" v-model="note.type"
                item-text="name" item-value="value"></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('date')"
                v-model="note.date"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field :dark="$store.state.isDarkMode"  outlined dense :label="$t('note address')" v-model="note.title"></v-text-field>
            </v-col>
            <v-col md="12">
              <quill-editor :class="{'white':$store.state.isDarkMode }" v-model="note.note" ref="myQuillEditor" :options="quillOptions">
              </quill-editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" depressed class="white--text" @click="submitNote" :loading="btnLoading">{{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      quillOptions: {
        // placeholder: "أدخل الملاحظة هنا...",
        direction: "ltr",
      },

      btnLoading: false,
      dialog: false,
      dateDialog: false,
      typeItems: [
      {
          name: this.$t("medical"),
          value: "medical",
        },
        {
          name: this.$t("educational"),
          value: "educational",
        },
        {
          name: this.$t("psychological"),
          value: "psychological",
        },
      ],
      note: {
        title: "",
        type: "",
        note: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        registration_record_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },

  methods: {
    async submitNote() {
      try {
        this.btnLoading = true;
        this.note.registration_record_id = this.getStudentRegId;
        await axios.post("/note", this.note);
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("noteSubmitted");
        this.dialog = false;
        this.note.title = "";
        this.note.type = "";
        this.note.note = "";
        this.note.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
        this.note.registration_record_id = this.getStudentRegId;
      } catch (err) {
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style>
.fr-second-toolbar {
  display: none;
}

.ql-editor {
  direction: rtl;
  text-align: right;
  font-family: "Cairo", sans-serif;
}
</style>
