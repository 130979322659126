<template>
    <div>
      <v-card class="mr-15 mt-10" elevation="0" v-if="!registerStatus">
        <v-row>
          <v-col md="6">
            <v-col md="3">
                <v-btn  color="green" dark :Loading="loadingBtn" @click="generatePass">
                    {{ isNew ? $t("Generate Password") : $t("Regenerat Password")  }}
                </v-btn>
            </v-col>
            <v-col md="12" v-if="userData!=null">
              <h2 class="mb-5">
                {{ $t('user name') }}:
                <span  style="font-weight: 100">{{ userData.username }}</span>
              </h2>
              <h2 class="mb-5">
                {{ $t('phone number') }}:
                <span  style="font-weight: 100">{{ userData.phone }}</span>
              </h2>
              <h2 class="mb-5">
              {{ $t('Password') }}:
                <span style="font-weight: 100">{{ userData.password }}</span>
              </h2>
            </v-col>
          </v-col>
          <v-col md="6" v-if="parentdata">
            <v-col md="12">
            </v-col>
            <v-col md="12">
              <h2 class="mb-5">
                {{ $t('user name') }}:
                <span  style="font-weight: 100">{{ parentdata.username }}</span>
              </h2>
              <h2 class="mb-5">
                {{ $t('phone number') }}:
                <span  style="font-weight: 100">{{ parentdata.phone }}</span>
              </h2>
              <h2 class="mb-5">
              {{ $t('Password') }}:
                <span style="font-weight: 100">{{ parentdata.password }}</span>
              </h2>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mr-15 mt-10" elevation="0" v-else>
        <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
      </v-card>
    </div>
  </template>
  
  <script>
import axios from 'axios';

  
  export default {
    data(){
        return{
          loadingBtn: false,
          parentdata: null,
          userData: null,
          Loading: false,
            isNew: true,
        };
    },
    methods: {
      async generatePass() {
        this.loadingBtn = true;
        try {
          const res = await axios.post(`/student/user/${this.$route.params.id}`);
          this.userData = res.data.data;
          console.log(this.userData);
        } catch (error) {
        }
        this.loadingBtn = false;
      }
    },

   async created() {
      console.log(this.student);
      try {
      this.Loading = true;
      const response = await axios.get(`/parent/reg_rec/${this.student.registration_record_id}`);
      const res = await axios.get(`/student/user/${this.$route.params.id}`);
      this.userData = res.data.data;
      this.parentdata = response.data.data;
      console.log(this.userData);

    } catch (err) {
      console.log("err", err);
    } finally {
      this.Loading = false;
    }
    },
    props: {
      student: {
        type: Object,
        default: () => {
          return {};
        },
      },
      registerStatus: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style>
  </style>